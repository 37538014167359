/* eslint-disable @typescript-eslint/no-unused-vars */
import { extend } from 'vee-validate';
import { required, email, length, numeric } from 'vee-validate/dist/rules';

extend('required', {
	...required,
	message: (fieldName: string, placeholders: string) => {
		return `Campo obligatorio`;
	},
});

extend('email', {
	...email,
	message: (fieldName: string, placeholders: string) => {
		return `Por favor, introduce un email válido`;
	},
});

extend('length', {
	...length,
	message: (fieldName: string, placeholders: any) => {
		return `Este campo debe tener ${placeholders.length} dígitos`;
	},
});

extend('numeric', {
	...numeric,
	message: 'Este campo sólo puede contener números',
});
