import UserService from '~/services/UserService';

export default function ({ store, route, $axios }) {
	if (process.client) {
		const user = store.getters.getUser;
		if (!user || !user.id) {
			store.commit('updateUserManaged', false);
			UserService.me($axios)
				.then((res) => {
					if (res.status === 200) {
						store.commit('setUser', res.data);
						if (res.data.ec_order_id) {
							localStorage.setItem('ec_order_id', res.data.ec_order_id);
						}
						store.commit('updateUserManaged', true);
					}
				})
				.catch((error) => {
					store.commit('updateUserManaged', true);
					return error;
				});
		}
	}
}
