var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"footer-upper"},[_c('b-container',[_c('div',{staticClass:"container-row"},[_c('b-card',{staticClass:"newsletter"},[_c('p',{staticClass:"card-title"},[_vm._v("Suscríbete a nuestra newsletter")]),_vm._v(" "),_c('ValidationObserver',{ref:"newsletter_obs",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
var validate = ref.validate;
return [_c('b-form',{attrs:{"inline":""},on:{"submit":function($event){$event.preventDefault();validate(); passes(_vm.onNewsletter);}}},[_c('label',{staticClass:"sr-only",attrs:{"for":'newsletter-email'}},[_vm._v("Email")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"slim":"","name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('InputText',{attrs:{"classes":"mb-2 mr-sm-3 mb-sm-0","errors":errors,"valid":valid,"with_form_group":false,"type":"email","label":"Email","placeHolder":"Email","id":"newsletter-email","model":_vm.email},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"checks-container"},[_c('Checkbox',{attrs:{"id":"newsletter_advertising","model":_vm.advertising,"values":{ checked: 'T', unchecked: 'F' }},model:{value:(_vm.advertising),callback:function ($$v) {_vm.advertising=$$v},expression:"advertising"}},[_vm._v("Deseo recibir información y promociones especiales de A3SATEL por cualquier medio, incluyendo los medios electrónicos")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"slim":"","name":"newsletter_privacy","rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('Checkbox',{attrs:{"errors":errors,"valid":valid,"id":"newsletter_privacy","model":_vm.privacy},model:{value:(_vm.privacy),callback:function ($$v) {_vm.privacy=$$v},expression:"privacy"}},[_vm._v("He leído y acepto "),_c('a',{attrs:{"href":_vm.legalTermUrl,"target":"_blank"}},[_vm._v("aviso legal")]),_vm._v(" y la "),_c('a',{attrs:{"href":_vm.anchor_policy,"target":"_blank"}},[_vm._v("política de privacidad")])])]}}],null,true)})],1),_vm._v(" "),_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v("Suscribirme")])],1)]}}])})],1),_vm._v(" "),_c('div',{staticClass:"rrss"},[_c('p',[_vm._v("Únete a la comunidad")]),_vm._v(" "),_c('b-button-toolbar',{attrs:{"aria-label":"Enlaces a redes sociales"}},_vm._l((_vm.footer.content.rrss_links),function(rrss_link,index){return _c('b-button',{key:'rrss-links-' + index,staticClass:"btn-icon btn-sm",attrs:{"href":rrss_link.url,"target":"_blank","variant":"outline-secondary"}},[_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(rrss_link.title))]),_vm._v(" "),_c('span',{staticClass:"icon",domProps:{"innerHTML":_vm._s(_vm.$feathericons[rrss_link.icon].toSvg())}})])}),1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }