import User from '~/models/user';
import ApiHelper from '~/helpers/api-helper';

export default class UserService {
	private static fields = ['email', 'name', 'surname', 'phone', 'password', 'confirm_password', 'privacy', 'commercial_consent'];
	private static company_fields = [
		'company_cif',
		'company_name',
		'company_address',
		'company_zip',
		'company_city',
		'company_province_id',
		'company_email',
		'company_phone',
	];

	public static BITRIX_CALL_ME_TYPE = 1;
	public static BITRIX_NEWSLETTER_TYPE = 2;
	public static BITRIX_SIMPLE_DEMO_TYPE = 3;
	public static BITRIX_DEMO_TYPE = 4;

	public static me($axios: any) {
		return $axios.get('/v1/user/me', {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		});
	}

	public static register($axios: any, user: User) {
		const data = ApiHelper.buildParams(this.fields, user);
		return $axios.post(`/user/registration`, data);
	}

	public static registerWithCompany($axios: any, user: User, company: any, new_company: boolean, legalTerm: any = {}) {
		let data = ApiHelper.buildParams(this.fields, user);
		if (new_company) {
			const data_company = ApiHelper.buildParams(this.company_fields, company);
			data = { ...data, ...data_company };
		} else {
			data.external_ref = company.external_ref;
		}
		data = { ...data, ...legalTerm };
		data.new_company = new_company;
		return $axios.post(`/user/full-registration`, data);
	}

	public static bitrix($axios: any, data: any) {
		return $axios.post(`/user/bitrix`, data);
	}

	public static createCompany($axios: any, company: any) {
		const data = ApiHelper.buildParams(this.company_fields, company);
		return $axios.post(`/user/create/company`, data, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		});
	}

	public static updateCompany($axios: any, company_id: any) {
		const data = { company_id };
		return $axios.post(`/user/update/company`, data, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		});
	}

	public static checkPrivilege($store: any, privilege: string) {
		const user = $store.state.user;
		for (const userPrivilege of user['privileges']) {
			if (userPrivilege == privilege) {
				return true;
			}
		}
		return false;
	}

	public static checkLegalTerm($axios: any, userId: number) {
		return $axios.get(`/legalTerm/${userId}`, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		});
	}

	public static storeLegalTerm($axios: any, data: any) {
		return $axios.put(`/legalTerm/${data.user_id}`, data, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		});
	}
}
