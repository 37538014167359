import UserService from '~/services/UserService';

export default function (ctx) {
	ctx.store.commit('updateUserManaged', false);
	UserService.me(ctx.$axios)
		.then((res) => {
			if (res.status === 200) {
				if (res.data.activated && res.data.activated === 2) {
					localStorage.removeItem('token');
					localStorage.removeItem('ec_order_id');
					ctx.store.commit('updateUserManaged', false);
					ctx.store.commit('setUser', null);
					ctx.store.commit('updateShoppingCart', { lines: [] });
				} else {
					ctx.store.commit('setUser', res.data);
					if (res.data.ec_order_id) {
						localStorage.setItem('ec_order_id', res.data.ec_order_id);
					}
					ctx.store.commit('updateUserManaged', true);
				}
			}
		})
		.catch((error) => {
			ctx.store.commit('updateUserManaged', true);
			return error;
		});
}
