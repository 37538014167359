







































































































import { Component, Vue } from 'nuxt-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import MainNav from '~/components/core/MainNav.vue';
import SectionService from '~/services/SectionService';
import HeaderModel from '~/models/header_model';
import ShoppingCart from '~/components/core/ShoppingCart.vue';
import InputText from '~/components/inputs/InputText.vue';
import Checkbox from '~/components/inputs/Checkbox.vue';
import UserService from '~/services/UserService';
import ApiHelper from '~/helpers/api-helper';
import ModalSignUp from '~/components/modals/ModalSignUp.vue';
import ModalDemo from '~/components/modals/ModalDemo.vue';
import ModalNewLegalTerms from '../modals/ModalNewLegalTerms.vue';

@Component({
	components: {
		ModalSignUp,
		Checkbox,
		InputText,
		ShoppingCart,
		MainNav,
		ModalNewLegalTerms,
		ValidationProvider,
		ValidationObserver,
	},
})
export default class Header extends Vue {
	private header: HeaderModel = new HeaderModel();
	private loaded: boolean = false;
	private phone: string = '';
	private privacy: boolean = false;
	private public_url = process.env.APP_URL;
	private anchor_policy = this.public_url + 'legalTerm/politicaprivacidad';

	$refs!: {
		header_obs: InstanceType<typeof ValidationObserver>;
		modalSignUp: InstanceType<typeof ModalSignUp>;
		configurationModalDemo: InstanceType<typeof ModalNewLegalTerms>;
	};
	
	async fetch() {
		await this.getHeader();
	}

	get loggedIn() {
		return this.$store.state.user && this.$store.state.user.id;
	}

	get loggedUser() {
		return this.$store.state.user;
	}

	async getHeader() {
		this.loaded = false;
		await SectionService.getByType(this.$axios, SectionService.HEADER_TYPE)
			.then((res: any) => {
				if (res) {
					this.header = HeaderModel.copy(res.data);
					this.loaded = true;
				}
			})
			.catch((error: any) => {
				this.loaded = true;
				console.log(error);
				// ApiHelper.handleErrors(error, {}, this.$nuxt);
			});
	}

	logout() {
		localStorage.removeItem('token');
		localStorage.removeItem('ec_order_id');
		this.$store.commit('setUser', null);
		this.$store.commit('updateShoppingCart', { lines: [] });
	}

	callMe() {
		this.$nuxt.$loading.start();
		UserService.bitrix(this.$axios, { type: UserService.BITRIX_CALL_ME_TYPE, data: this.phone })
			.then((res: any) => {
				if (res.status === 200) {
					this.$nuxt.$loading.finish();
					this.$store.commit('setAlert', {
						show: true,
						message: '¡Solicitud enviada! Te llamaremos lo antes posible',
						type: 'success',
					});
					if (this.$refs.header_obs) {
						this.$refs.header_obs.reset();
					}
					this.phone = '';
					this.privacy = false;
				}
			})
			.catch((error: any) => {
				this.$nuxt.$loading.finish();
				ApiHelper.handleErrors(error, this.$refs.header_obs, this.$store);
			});
	}

	showSignUpModal() {
		this.$refs.modalSignUp.show();
	}

	mounted() {
		setTimeout(() => {
			if (this.loggedUser && this.loggedUser.name) {
			UserService.checkLegalTerm(this.$axios, this.loggedUser.id)
				.then((res: any) => {
					if (!res.data) {
						this.$refs.configurationModalDemo.show();
					}
				})
		}
		}, 5000)
	}

	storeLegalTermTimestamp($event: any) {
		UserService.storeLegalTerm(this.$axios, { 
			user_id: this.loggedUser.id,
			accepted_legal_terms: 1,
			accept_legal_terms_timestamp: $event,
			legal_terms_version: 2
		})
			.catch((error: any) => {
				ApiHelper.handleErrors(error, {}, this.$store);
			});
	}
}
