











import { Component, Vue } from 'nuxt-property-decorator';
import Footer from '~/components/core/Footer.vue';
import Logo from '~/components/atoms/Logo.vue';
import Alert from '~/components/core/Alert.vue';

@Component({
	name: 'funnel',
	components: {
		Alert,
		Footer,
		Logo,
	},
})
export default class Funnel extends Vue {
	head() {
		return {
			bodyAttrs: {
				class: 'page-funnel',
			},
			link: [
				{
					hid: 'canonical',
					rel: 'canonical',
					href: process.env.APP_URL + this.$route.path,
				},
			],
		};
	}
}
