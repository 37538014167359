export default class HeaderModel {
	id: number;
	content: {
		main_navigation: {
			left: any[];
			right: any[];
		};
		banner: {
			text_content: string;
		};
		contact_phones: any[];
	};
	type: number;

	constructor() {
		this.id = 0;
		this.content = {
			main_navigation: {
				left: [],
				right: [],
			},
			banner: {
				text_content: '',
			},
			contact_phones: [],
		};
		this.type = 1;
	}

	public static copy(model: any) {
		const aux = new HeaderModel();
		aux.id = model.id;
		aux.content = model.content;
		aux.type = model.type;
		return aux;
	}
}
