import ApiHelper from '~/helpers/api-helper';
import Order from '~/models/order';

export default class OrderService {
	public static PENDING_STATUS = 1;
	public static UNDER_REVIEW_STATUS = 2;
	public static PAID_STATUS = 3;

	private static fields = ['lines'];

	public static get($axios: any, $store: any, id: any) {
		return $axios
			.get(`/ec_order/${id}`, {
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('token'),
				},
			})
			.then((res: any) => {
				if (res.status === 200) {
					$store.commit('updateShoppingCart', res.data);
					$store.commit('setCartGtmLoaded', true);
				}
			})
			.catch((error: any) => {
				// Esto es por el privilegio de detalle, cada vez que lo incumpla hay que quitar el ID de pedido y vaciar el carrito
				if (error.response && error.response.status == 502) {
					localStorage.removeItem('ec_order_id');
					$store.commit('updateShoppingCart', { lines: [] });
				} else {
					ApiHelper.handleErrors(error, {}, $store);
				}
			});
	}

	public static getCheckoutOptions($axios: any, id: any) {
		return $axios.get(`/ec_order/options/${id}`, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		});
	}

	public static store($axios: any, order: Order) {
		const data = ApiHelper.buildParams(this.fields, order);
		return $axios.post(`/ec_order`, data, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		});
	}

	public static update($axios: any, order: Order) {
		const data = ApiHelper.buildParams(this.fields, order);
		return $axios.put(`/ec_order/${order.id}`, data, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		});
	}

	public static checkCompany($axios: any, company_cif: any, email: string, registration = false) {
		const data = { company_cif, email, registration };
		return $axios.post(`/ec_order/check-company`, data, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		});
	}

	public static paymentIntent($axios: any, order_id: any, shopping_code: any) {
		const data = { order_id, shopping_code };
		return $axios.post(`/ec_order/paymentIntent/${order_id}`, data, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		});
	}

	public static paymentConfirmation($axios: any, order_id: any) {
		const data = { order_id };
		return $axios.post(`/ec_order/confirm/${order_id}`, data, {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		});
	}
}
