



























































import { Component, Vue, Prop } from 'vue-property-decorator';
import Logo from '~/components/atoms/Logo.vue';
import SectionService from '~/services/SectionService';
import FooterModel from '~/models/footer_model';
import Newsletter from '~/components/core/Newsletter.vue';
import Sitemap from '~/components/core/Sitemap.vue';

@Component({
	components: {
		Sitemap,
		Newsletter,
		Logo,
	},
})
export default class Footer extends Vue {
	@Prop({ type: Boolean, required: false, default: true }) newsletter: any;
	@Prop({ type: Boolean, required: false, default: true }) sitemap: any;

	private footer: FooterModel = new FooterModel();
	private loaded: boolean = false;

	async fetch() {
		await this.getFooter();
	}

	async getFooter() {
		this.loaded = false;
		await SectionService.getByType(this.$axios, SectionService.FOOTER_TYPE)
			.then((res: any) => {
				if (res) {
					this.footer = FooterModel.copy(res.data);
					this.loaded = true;
				}
			})
			.catch((error: any) => {
				this.loaded = true;
				console.log(error);
				// ApiHelper.handleErrors(error, {}, this.$nuxt);
			});
	}

	getImgUrl(img: string) {
		return process.env.API_IMG_URL + img;
	}
}
