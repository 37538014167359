var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal",attrs:{"size":"xl","hide-footer":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('div',{staticClass:"modal-heading"},[_c('h4',{staticClass:"modal-title"},[_vm._v("Crear cuenta")])]),_vm._v(" "),_c('b-button',{staticClass:"btn btn-close",attrs:{"size":"sm"},on:{"click":function($event){return close()}}},[_c('span',{staticClass:"sr-only"},[_vm._v("Cerrar")])])]}}])},[_vm._v(" "),_c('div',{staticClass:"form-container mt-0"},[_c('ValidationObserver',{ref:"modal_signup_obs",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
var validate = ref.validate;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();validate(); passes(_vm.onSignUp);}}},[_c('b-row',[(!_vm.company_has_admin)?_c('b-alert',{attrs:{"show":"","variant":"warning"}},[_vm._v("\n\t\t\t\t\t\tActualmente esta empresa no tiene usuario administrador por lo que te asiganará como tal\n\t\t\t\t\t")]):_vm._e(),_vm._v(" "),_c('b-col',{attrs:{"cols":"6"}},[_c('legend',[_vm._v("Datos personales")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('InputText',{attrs:{"id":"name","errors":errors,"valid":valid,"label":"Nombre *","model":_vm.user.name},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"surname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('InputText',{attrs:{"id":"surname","errors":errors,"valid":valid,"label":"Apellidos *","model":_vm.user.surname},model:{value:(_vm.user.surname),callback:function ($$v) {_vm.$set(_vm.user, "surname", $$v)},expression:"user.surname"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('InputText',{attrs:{"id":"email","type":"email","errors":errors,"valid":valid,"label":"E-mail *","model":_vm.user.email},on:{"blur":function($event){return _vm.searchCompany()}},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('InputText',{attrs:{"id":"phone","errors":errors,"valid":valid,"label":"Teléfono *","model":_vm.user.phone},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('InputText',{attrs:{"id":"password","type":"password","errors":errors,"valid":valid,"label":"Contraseña *","model":_vm.user.password},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"confirm_password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('InputText',{attrs:{"id":"confirm","type":"password","errors":errors,"valid":valid,"label":"Repetir contraseña *","model":_vm.user.confirm_password},model:{value:(_vm.user.confirm_password),callback:function ($$v) {_vm.$set(_vm.user, "confirm_password", $$v)},expression:"user.confirm_password"}})]}}],null,true)}),_vm._v(" "),_c('b-form-group',[_c('p',{staticClass:"mb-4"},[_vm._v("Al registrarte estás de acuerdo con nuestros "),_c('a',{staticClass:"link",attrs:{"href":_vm.anchor_terms,"target":"_blank"}},[_vm._v("términos y condiciones de venta")])]),_vm._v(" "),_c('div',{staticClass:"fields-row"},[_c('ValidationProvider',{attrs:{"name":"sign_up_privacy","rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('Checkbox',{attrs:{"errors":errors,"valid":valid,"id":"sign_up_privacy","model":_vm.user.privacy},on:{"input":_vm.registerLegalTermTimestamp},model:{value:(_vm.user.privacy),callback:function ($$v) {_vm.$set(_vm.user, "privacy", $$v)},expression:"user.privacy"}},[_vm._v("\n\t\t\t\t\t\t\t\t\t\tHe leído y acepto el "),_c('a',{staticClass:"link",attrs:{"target":"_blank","href":_vm.legalTermUrl}},[_vm._v("aviso legal")]),_vm._v(" y la "),_c('a',{staticClass:"link",attrs:{"href":_vm.anchor_policy,"target":"_blank"}},[_vm._v("política de privacidad")]),_vm._v(" *\n\t\t\t\t\t\t\t\t\t")])]}}],null,true)}),_c('br'),_vm._v(" "),_c('Checkbox',{attrs:{"id":"sign_up_commercial_consent","model":_vm.user.commercial_consent},model:{value:(_vm.user.commercial_consent),callback:function ($$v) {_vm.$set(_vm.user, "commercial_consent", $$v)},expression:"user.commercial_consent"}},[_vm._v(" Deseo recibir información y promociones especiales de A3SATEL por cualquier medio, incluyendo los medios electrónicos ")])],1)])],1),_vm._v(" "),_c('b-col',{attrs:{"cols":"6"}},[_c('legend',[_vm._v("Datos de empresa")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"slim":"","name":"company_cif","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('InputText',{attrs:{"id":"company-cif","errors":errors,"valid":valid,"label":"CIF *","placeHolder":"Debes rellenar primero el campo email para poder introducir el CIF","model":_vm.company.company_cif,"disabled":!_vm.user.email},on:{"blur":function($event){return _vm.searchCompany()}},model:{value:(_vm.company.company_cif),callback:function ($$v) {_vm.$set(_vm.company, "company_cif", $$v)},expression:"company.company_cif"}})]}}],null,true)}),_vm._v(" "),(_vm.new_company)?_c('ValidationProvider',{attrs:{"slim":"","name":"company_name","rules":_vm.new_company ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('InputText',{attrs:{"id":"company-name","label":_vm.new_company ? 'Razón social *' : 'Razón social',"model":_vm.company.company_name,"errors":errors,"valid":valid,"disabled":!_vm.new_company},model:{value:(_vm.company.company_name),callback:function ($$v) {_vm.$set(_vm.company, "company_name", $$v)},expression:"company.company_name"}})]}}],null,true)}):_vm._e(),_vm._v(" "),(_vm.new_company)?[_c('b-row',{staticClass:"mb-0"},[_c('b-col',[_c('ValidationProvider',{attrs:{"slim":"","name":"company_address","rules":_vm.new_company ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('InputText',{attrs:{"id":"company-address","label":_vm.new_company ? 'Dirección *' : 'Dirección',"model":_vm.company.company_address,"errors":errors,"valid":valid,"disabled":!_vm.new_company},model:{value:(_vm.company.company_address),callback:function ($$v) {_vm.$set(_vm.company, "company_address", $$v)},expression:"company.company_address"}})]}}],null,true)})],1),_vm._v(" "),_c('b-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"slim":"","name":"company_zip","rules":_vm.new_company ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('InputText',{attrs:{"id":"company-zip","form_group_classes":"short","label":_vm.new_company ? 'C.P. *' : 'C.P.',"model":_vm.company.company_zip,"errors":errors,"valid":valid,"disabled":!_vm.new_company},model:{value:(_vm.company.company_zip),callback:function ($$v) {_vm.$set(_vm.company, "company_zip", $$v)},expression:"company.company_zip"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('b-row',{staticClass:"mb-0"},[_c('b-col',[_c('ValidationProvider',{attrs:{"slim":"","name":"company_city","rules":_vm.new_company ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('InputText',{attrs:{"id":"company-city","label":_vm.new_company ? 'Localidad *' : 'Localidad',"model":_vm.company.company_city,"errors":errors,"valid":valid,"disabled":!_vm.new_company},model:{value:(_vm.company.company_city),callback:function ($$v) {_vm.$set(_vm.company, "company_city", $$v)},expression:"company.company_city"}})]}}],null,true)})],1),_vm._v(" "),_c('b-col',[_c('ValidationProvider',{attrs:{"slim":"","name":"company_province_id","rules":_vm.new_company ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('ProvinceSelect',{attrs:{"id":"company-province_id","label":_vm.new_company ? 'Provincia *' : 'Provincia',"model":_vm.company.company_province_id,"errors":errors,"valid":valid,"disabled":!_vm.new_company},model:{value:(_vm.company.company_province_id),callback:function ($$v) {_vm.$set(_vm.company, "company_province_id", $$v)},expression:"company.company_province_id"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('ValidationProvider',{attrs:{"slim":"","name":"company_email","rules":_vm.new_company ? 'required|email' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('InputText',{attrs:{"id":"company-email","label":_vm.new_company ? 'Email *' : 'Email',"model":_vm.company.company_email,"errors":errors,"valid":valid,"disabled":!_vm.new_company},model:{value:(_vm.company.company_email),callback:function ($$v) {_vm.$set(_vm.company, "company_email", $$v)},expression:"company.company_email"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"slim":"","name":"company_phone","rules":_vm.new_company ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('InputText',{attrs:{"id":"company-phone","form_group_classes":"short","label":_vm.new_company ? 'Teléfono *' : 'Teléfono',"model":_vm.company.company_phone,"errors":errors,"valid":valid,"disabled":!_vm.new_company},model:{value:(_vm.company.company_phone),callback:function ($$v) {_vm.$set(_vm.company, "company_phone", $$v)},expression:"company.company_phone"}})]}}],null,true)})]:_vm._e()],2)],1),_vm._v(" "),_c('div',{staticClass:"form-actions"},[_c('b-button',{staticClass:"btn-lg",attrs:{"disabled":(_vm.cif_searched && _vm.company_has_admin) || _vm.saving,"type":"submit","variant":"primary"}},[_vm._v("Crear cuenta")])],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }