




import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Logo extends Vue {
	private logo = require('~/assets/img/brand/logo-a3satel-header.png');
}
