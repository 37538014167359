
















import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import LegalNotice from '../core/LegalNotice.vue';

@Component({
	components: {
        LegalNotice
	},
})
export default class ModalNewLegalTerms extends Vue {
	selected: any = null;
	


	$refs!: {
		modal: any;
	};

	show() {
		this.$refs.modal.show();
	}

	hide() {
		this.$refs.modal.hide();
	}

	accept=() => {
		console.log(Date.now());
	}

    getDate() {
        console.log(new Date().toString())
        return moment().format('YYYY-MM-DD HH:mm:ss');
    }

}
