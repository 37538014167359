























































import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import InputText from '~/components/inputs/InputText.vue';
import Checkbox from '~/components/inputs/Checkbox.vue';
import UserService from '~/services/UserService';
import ApiHelper from '~/helpers/api-helper';

@Component({
	components: {
		Checkbox,
		InputText,
		ValidationProvider,
		ValidationObserver,
	},
})
export default class Newsletter extends Vue {
	@Prop({ required: true }) footer: any;

	private email: string = '';
	private privacy: boolean = false;
	private public_url = process.env.APP_URL;
	private anchor_policy = this.public_url + 'legalTerm/politicaprivacidad';
	private legalTermUrl = this.public_url + 'legalTerm/avisolegal';
	private advertising: string = 'F';

	$refs!: {
		newsletter_obs: InstanceType<typeof ValidationObserver>;
	};

	onNewsletter() {
		this.$nuxt.$loading.start();
		UserService.bitrix(this.$axios, { type: UserService.BITRIX_NEWSLETTER_TYPE, data: { email: this.email, advertising: this.advertising } })
			.then((res: any) => {
				if (res.status === 200) {
					this.$nuxt.$loading.finish();
					this.$store.commit('setAlert', {
						show: true,
						message: '¡Solicitud enviada! Gracias por apuntarte a nuestra newsletter',
						type: 'success',
					});
					if (this.$refs.newsletter_obs) {
						this.$refs.newsletter_obs.reset();
					}
					this.email = '';
					this.privacy = false;
					this.advertising = 'F';
				}
			})
			.catch((error: any) => {
				this.$nuxt.$loading.finish();
				ApiHelper.handleErrors(error, this.$refs.newsletter_obs, this.$store);
			});
	}
}
