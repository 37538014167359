














































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class InputText extends Vue {
	@Prop({ default: null, required: false }) private model: any | undefined;
	@Prop({ default: null, required: true }) private label: any | '';
	@Prop({ default: null, required: true }) private id: any | '';
	@Prop({ default: null, required: false }) private classes: any | '';
	@Prop({ default: null, required: false }) private form_group_classes: any | '';
	@Prop({ default: null, required: false }) private helpText: any | '';
	@Prop({ default: null, required: false }) private placeHolder: any | '';
	@Prop({ default: null, required: false }) private errors: any | [];
	@Prop({ default: true, required: false }) private with_form_group: any | boolean;
	@Prop({ default: null, required: false }) private valid: any | boolean;
	@Prop({ default: null, required: false }) private disabled: any | boolean;
	@Prop({ default: false, required: false }) private sr_only: any | boolean;
	@Prop({ default: null, required: false }) private validFeedbackText: any | undefined;
	@Prop({ default: 'text', required: false }) private type: any | '';

	private value = null;
	private component_type = 'text';

	@Watch('model')
	onModelChanged(model: any) {
		this.value = model;
	}

	mounted() {
		if (this.type) {
			this.component_type = this.type;
		}
		if (this.model) {
			this.value = this.model;
		}
	}

	get state() {
		return this.errors == null || this.errors.length == 0 ? null : this.errors.length == 0;
	}

	get invalidFeedback() {
		if (this.errors) {
			return this.errors[0];
		}
		return null;
	}

	get validFeedback() {
		if (this.validFeedbackText) {
			return !this.errors && this.valid ? this.validFeedbackText : '';
		}
		return '';
	}

	showPassword() {
		if (this.component_type === 'password') {
			this.component_type = 'text';
		} else {
			this.component_type = 'password';
		}
	}
}
