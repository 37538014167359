






























import { Component, Vue, Prop } from 'vue-property-decorator';
import Logo from '~/components/atoms/Logo.vue';
import HeaderModel from '~/models/header_model';

@Component({
	components: {
		Logo,
	},
})
export default class MainNav extends Vue {
	@Prop({ required: true }) header: any;
}
