
























































































































































































import { Component, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Checkbox from '~/components/inputs/Checkbox.vue';
import InputText from '~/components/inputs/InputText.vue';
import UserService from '~/services/UserService';
import ApiHelper from '~/helpers/api-helper';
import User from '~/models/user';
import OrderService from '~/services/OrderService';
import ProvinceSelect from '~/components/inputs/ProvinceSelect.vue';
import PrivacySummaryText from '~/components/core/PrivacySummaryText.vue';
import moment from 'moment';

@Component({
	components: {
		PrivacySummaryText,
		ProvinceSelect,
		Checkbox,
		InputText,
		ValidationProvider,
		ValidationObserver,
	},
})
export default class ModalSignUp extends Vue {
	user: User = new User();
	new_company: boolean = false;
	company_has_admin: boolean = true;
	cif_searched: boolean = false;
	legalTerm: any = {};
	saving: boolean = false;
	company = {
		id: null,
		company_cif: null,
		company_name: null,
		company_address: null,
		company_zip: null,
		company_city: null,
		company_province_id: null,
		company_email: null,
		company_phone: null,
		external_ref: null,
	};
	private public_url = process.env.APP_URL;
	private anchor_policy = this.public_url + 'legalTerm/politicaprivacidad';
	private anchor_terms = this.public_url + 'legalTerm/termsconditions';
	private legalTermUrl = this.public_url + 'legalTerm/avisolegal';

	$refs!: {
		modal: any;
		modal_signup_obs: InstanceType<typeof ValidationObserver>;
	};

	registerLegalTermTimestamp(inputCheck: any) {
		if (inputCheck) {
			this.legalTerm['accept_legal_terms_timestamp'] = moment().format('YYYY-MM-DD HH:mm:ss');
			this.legalTerm['legal_terms_version'] = 2;
		} else {
			this.legalTerm = {}
		}
	}

	show() {
		this.user = new User();
		this.company = {
			id: null,
			company_cif: null,
			company_name: null,
			company_address: null,
			company_zip: null,
			company_city: null,
			company_province_id: null,
			company_email: null,
			company_phone: null,
			external_ref: null,
		};
		this.new_company = false;
		this.company_has_admin = true;
		this.cif_searched = false;
		this.$refs.modal.show();
	}

	hide() {
		this.$refs.modal.hide();
	}

	onSignUp() {
		this.saving = true;
		this.$nuxt.$loading.start();
		UserService.registerWithCompany(this.$axios, this.user, this.company, this.new_company, this.legalTerm)
			.then((res: any) => {
				if (res.status === 200) {
					this.$nuxt.$loading.finish();
					this.hide();
					this.saving = false;
					if (res.data.user && res.data.token.token) {
						this.$store.commit('setUser', res.data.user);
						localStorage.setItem('token', res.data.token.token);
					} else {
						this.$store.commit('setAlert', {
							show: true,
							message: '¡Enhorabuena! El registro se ha completado correctamente. Por favor, revisa tu correo electrónico para activar tu cuenta',
							type: 'success',
						});
					}

				}
			})
			.catch((error: any) => {
				this.saving = false;
				this.$nuxt.$loading.finish();
				ApiHelper.handleErrors(error, this.$refs.modal_signup_obs, this.$store);
			});
	}

	searchCompany() {
		if (this.company.company_cif && this.user.email) {
			this.saving = true;
			this.$nuxt.$loading.start();
			OrderService.checkCompany(this.$axios, this.company.company_cif, this.user.email, true)
				.then((res: any) => {
					if (res.status === 200) {
						this.cif_searched = true;
						this.saving = false;
						this.$nuxt.$loading.finish();
						if (this.$refs.modal_signup_obs) {
							this.$refs.modal_signup_obs.reset();
						}
						if (res.data.error) {
							this.company_has_admin = true;
							this.new_company = false;
							this.$store.commit('setAlert', {
								show: true,
								message: res.data.error,
								type: 'danger',
							});
						} else {
							this.company_has_admin = res.data.admin;
							this.new_company = !res.data.company;
							if (this.new_company) {
								this.company = {
									id: null,
									company_cif: this.company.company_cif,
									company_name: null,
									company_address: null,
									company_zip: null,
									company_city: null,
									company_province_id: null,
									company_email: null,
									company_phone: null,
									external_ref: null,
								};
							} else {
								this.company.external_ref = res.data.company.IDORG;
							}
						}
					}
				})
				.catch((error: any) => {
					this.saving = false;
					this.$nuxt.$loading.finish();
					if (error.response && error.response.status == 450 && error.response.data.company) {
						this.new_company = false;
						this.company_has_admin = true;
						this.cif_searched = true;
					}
					ApiHelper.handleErrors(error, this.$refs.modal_signup_obs, this.$store);
				});
		}
	}
}
