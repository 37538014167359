











import { Component, Vue } from 'nuxt-property-decorator';

@Component({})
export default class Alert extends Vue {
	onHide() {
		this.$store.commit('setAlert', {
			show: false,
			message: '',
			type: '',
		});
	}

	get showAlert() {
		return this.$store.state.alert;
	}
}
