export default class User {
	id: any;
	name: string;
	surname: string;
	email: string;
	phone: string;
	password: string;
	confirm_password: string;
	privacy: boolean;
	commercial_consent: boolean;
	profile_id: any;
	company: any;

	constructor() {
		this.id = null;
		this.name = '';
		this.surname = '';
		this.email = '';
		this.phone = '';
		this.password = '';
		this.confirm_password = '';
		this.privacy = false;
		this.commercial_consent = false;
		this.profile_id = null;
		this.company = null;
	}

	public static copy(model: any) {
		const aux = new User();
		aux.id = model.id;
		aux.name = model.name;
		aux.surname = model.surname;
		aux.email = model.email;
		aux.phone = model.phone;
		aux.password = model.password;
		aux.confirm_password = model.confirm_password;
		aux.privacy = model.privacy;
		aux.commercial_consent = model.commercial_consent;
		aux.profile_id = model.profile_id;
		aux.company = model.company;
		return aux;
	}
}
