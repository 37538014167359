export default class FooterModel {
	id: number;
	content: {
		partners: any[];
		locations: any[];
		// eslint-disable-next-line camelcase
		rrss_links: any[];
		sitemap_columns: any[];
	};
	type: number;
	constructor() {
		this.id = 0;
		this.content = {
			partners: [],
			locations: [],
			rrss_links: [],
			sitemap_columns: [],
		};
		this.type = 2;
	}

	public static copy(model: any) {
		const aux = new FooterModel();
		aux.id = model.id;
		aux.content = model.content;
		aux.type = model.type;
		return aux;
	}
}
