import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6a906f42 = () => interopDefault(import('../pages/cesta/index.vue' /* webpackChunkName: "pages/cesta/index" */))
const _7ce33aee = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _603dcf5e = () => interopDefault(import('../pages/cesta/gracias.vue' /* webpackChunkName: "pages/cesta/gracias" */))
const _1ede0278 = () => interopDefault(import('../pages/legalTerm/_slug/index.vue' /* webpackChunkName: "pages/legalTerm/_slug/index" */))
const _73837ca5 = () => interopDefault(import('../pages/soluciones/_slug/index.vue' /* webpackChunkName: "pages/soluciones/_slug/index" */))
const _7dc84453 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5cbc754e = () => interopDefault(import('../pages/_slug/index.vue' /* webpackChunkName: "pages/_slug/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cesta",
    component: _6a906f42,
    name: "cesta"
  }, {
    path: "/error",
    component: _7ce33aee,
    name: "error"
  }, {
    path: "/cesta/gracias",
    component: _603dcf5e,
    name: "cesta-gracias"
  }, {
    path: "/legalTerm/:slug",
    component: _1ede0278,
    name: "legalTerm-slug"
  }, {
    path: "/soluciones/:slug",
    component: _73837ca5,
    name: "soluciones-slug"
  }, {
    path: "/",
    component: _7dc84453,
    name: "index"
  }, {
    path: "/:slug",
    component: _5cbc754e,
    name: "slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
