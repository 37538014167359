export default class ApiHelper {
	public static setQueryParams(pagination: any, filters: any, order: any) {
		const params = new URLSearchParams();
		if (pagination) {
			params.append('page', pagination.page);
			params.append('pagination', pagination.itemsPerPage);
		}
		if (order.sortBy) {
			if (order.sortDesc) {
				params.append('sort', '-' + order.sortBy);
			} else {
				params.append('sort', order.sortBy);
			}
		}
		if (Object.keys(filters).length) {
			Object.keys(filters).forEach((key) => {
				if (filters[key] !== undefined && filters[key] !== null) {
					params.append(key, filters[key]);
				}
			});
		}
		return params;
	}

	public static buildParams(fields: any[], model: any): any {
		const params: any = {};
		for (const field of fields) {
			if (model[field] != undefined && !Array.isArray(model[field]) && typeof model[field] !== 'object') {
				params[field] = model[field];
			} else if (model[field] != undefined && (Array.isArray(model[field]) || typeof model[field] === 'object')) {
				params[field] = JSON.stringify(model[field]);
			}
		}
		return params;
	}

	public static handleErrors(error: any, formRef = {}, store: any, show_in_alert = false) {
		let message = '';
		if (error.response && error.response.status == 500) {
			if (error.response.data.error && typeof error.response.data.error === 'string') {
				message = error.response.data.error;
			} else if (error.config.method == 'get') {
				message = 'Se ha producido un error en el servidor al solicitar datos';
			} else if (error.config.method == 'post' || error.config.method == 'put') {
				message = 'Se ha producido un error en el servidor al guardar datos';
			}
		}
		if (error.response && error.response.status == 502) {
			message = 'No tienes permisos para realizar esta acción';
		}
		if (error.response && error.response.status == 404) {
			message = 'El recurso solicitado no existe';
		}
		if (error.response && error.response.status == 429) {
			message = 'Se ha superado el número de solicitudes. Por favor, espere unos minutos';
		}
		if (store && message) {
			store.commit('setAlert', {
				show: true,
				message,
				type: 'danger',
			});
		}
		if (error.response && (error.response.status == 450 || error.response.status == 422) && (Object.keys(formRef).length || show_in_alert)) {
			this.handleValitationErrors(formRef, error.response.data, error.response.status, store, show_in_alert);
		}
	}

	private static handleValitationErrors(ref: any, errorsFields: any, status: any, store: any, show_in_alert: any) {
		if (status == 422 || errorsFields.errors) {
			errorsFields = errorsFields.errors;
		}
		Object.keys(errorsFields).forEach((key) => {
			if (show_in_alert) {
				store.commit('setAlert', {
					show: true,
					message: errorsFields[key][0],
					type: 'danger',
				});
			} else if (ref.errors[key]) {
				ref.errors[key].push(errorsFields[key][0]);
			} else {
				ref.errors[key] = [];
				ref.errors[key].push(errorsFields[key][0]);
			}
		});
		return ref;
	}
}
