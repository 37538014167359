export const state = () => ({
	user: null,
	user_managed: false,
	shopping_cart: { lines: [] },
	shopping_code: null,
	cart_gtm_loaded: false,
	alert: {
		show: false,
		message: '',
		type: '',
	},
	gtm_event: null, // Esto es para setear eventos que se tengan que ejecutar antes de "view_item" como "select_item"
});

export const mutations = {
	setUser(state, user) {
		state.user = user;
	},
	setAlert(state, alert) {
		state.alert = alert;
	},
	updateShoppingCart(state, data) {
		state.shopping_cart = data;
	},
	updateShoppingCode(state, data) {
		state.shopping_code = data;
	},
	updateUserManaged(state, data) {
		state.user_managed = data;
	},
	setCartGtmLoaded(state, cart_gtm_loaded) {
		state.cart_gtm_loaded = cart_gtm_loaded;
	},
	setGtmEvent(state, event) {
		state.gtm_event = event;
	},
};

export const getters = {
	getUser(state) {
		return state.user;
	},
	getShoppingCart(state) {
		return state.shopping_cart;
	},
	getCartGtmLoaded(state) {
		return state.cart_gtm_loaded;
	},
	getGtmEvent(state) {
		return state.gtm_event;
	},
};
