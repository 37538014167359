













import { Component, Vue } from 'nuxt-property-decorator';

@Component({})
export default class PrivacySummaryText extends Vue {
	get legalText(): string {
		return `TÉRMINOS Y CONDICIONES DE COMPRA DE A3SATEL

FERNÁNDEZ Y CANTALEJO S.L.U., empresa perteneciente al GRUPO A3SATEL, bajo el nombre comercial A3SATEL, es la titular, propietaria y gestora de esta web y su tienda online. Por un compromiso con la transparencia y nuestros usuarios, aportamos los datos fiscales y de contacto:  
    • Domicilio social:  C/Camino de los Descubrimientos 11, 1º planta, 41092, Sevilla. 
    • CIF B41377854 
    •  Email: administraciononline@a3satel.com
    • Teléfono: 954 26 96 92 
El presente documento establece los términos y condiciones de uso de esta web, de su tienda online y de todas las transacciones que realicen mediante la misma. Éstas deben ser aceptadas por el Usuario antes de adquirir cualquiera de las soluciones ofertadas por A3SATEL en su tienda online. 
Por tanto, estas normas constituyen un CONTRATO entre A3SATEL y el CLIENTE, respecto al uso de la web y las transacciones comerciales realizadas mediante su web. El CLIENTE respetará íntegramente estas Condiciones Generales, las cuales podrán ser complementadas con Condiciones Particulares, recogidas en la descripción del servicio a adquirir y en documentación adicional, y que prevalecerán en caso de contradicción.
Como usuario o cliente declara expresamente conocer, entender y aceptar las condiciones de uso y estas condiciones generales de contratación, las condiciones particulares en su caso, así como la Política de Privacidad, la Política de Cookies y el Aviso legal que rigen este sitio y su tienda. En caso contrario, deberá abstenerse de continuar navegando por la web de A3SATEL y por su tienda. 
En particular, el CLIENTE declara y garantiza que no contrata el Servicio en ningún caso como consumidor (es decir, como una persona que actúa en un ámbito ajeno a su actividad empresarial o profesional). Del mismo modo, declara ser mayor de edad y tener la capacidad jurídica y de obrar necesarias para navegar por esta web y acceder a los productos en ella ofertados. 

    1. SOBRE EL SERVICIO 

        1.1. Descripción y alcance del servicio
El servicio ofrecido por A3SATEL consiste en el derecho de uso de sus soluciones en modalidad SAAS. Usted puede encontrar el catálogo de nuestros productos y servicios publicados en https://online.a3satel.com. De producirse un error en la web y mostrarse un producto que realmente no se encuentra disponible, A3SATEL informará de inmediato de esta falta de disponibilidad al CLIENTE y será devuelta cualquier cantidad que pudiera haber abonado sin demora alguna.
El Servicio se presta por A3SATEL como una relación de naturaleza mercantil, pues está diseñado y dirigido únicamente para satisfacer las necesidades empresariales o profesionales. Por tanto, no aplica la Ley General para la Defensa de los Consumidores y Usuarios y sus leyes complementarias. 
Las partes siguen manteniendo su independencia y personalidad jurídica distintas entre sí, y reconocen expresamente que no existe relación laboral alguna entre ellas. 
Las soluciones ofertadas a través de la tienda online de A3SATEL tienen características diferentes, por ello le recomendamos la lectura completa de nuestros avisos legales y las especificaciones técnicas de cada uno de nuestros productos y servicios disponibles en la web y en los presentes términos, antes de efectuar la compra. 
Todas las soluciones de nuestro catálogo aparecen unidos a la información sobre las características básicas, condiciones e información adicional del mismo. El CLIENTE es el único responsable de verificar que dichas características cumplen con sus expectativas antes de efectuar la compra de los productos o contratar nuestros servicios.
Algunas soluciones pueden requerir de ciertos medios técnicos y/o servicios adicionales para su correcto funcionamiento. Necesidades que, en su caso, se indican en la descripción del servicio a contratar. Por favor, lea detenidamente la información disponible en la web junto a cada uno de los productos antes de la adquisición de cualquiera de ellos. El CLIENTE será el único responsable de disponer de los medios técnicos necesarios para que la solución contratada funcione correctamente.  A3SATEL no se hará responsable de que el CLIENTE cuente con los equipos, conexiones y medios técnicos necesarios. 
El CLIENTE deberá asegurarse bajo su propia responsabilidad de disponer de los sistemas de seguridad, de detección de virus y procedimientos necesarios para evitar el acceso no autorizado, el uso indebido o la alteración del Servicio, así como procedimientos de protección de datos y recuperación ante desastres. 

        1.2. Obligaciones del CLIENTE
El CLIENTE deberá de estar al corriente de sus obligaciones con GRUPO A3SATEL para poder comprar o adquirir el producto o servicio, así como aceptar las presentes Condiciones y el resto de los textos legales disponibles en https://online.a3satel.com. 
Igualmente, es necesario estar registrado como usuario de la web de A3SATEL para poder realizar cualquier transacción comercial mediante la tienda online disponible en el sitio. Para más información puede consultar el punto 6 del “Aviso legal” disponible en esta web. 
El CLIENTE será responsable de asegurar las correctas condiciones para el funcionamiento del producto y/o recepción del servicio contratado. En estas condiciones se incluye la correcta instalación del producto contratado en el caso de que dicho producto deba instalarse, el estado del equipo informático, dispositivos o sistemas informáticos relacionados con dicha instalación, así como aspectos relacionados con el suministro eléctrico y control de acceso a los productos o servicios contratados. A3SATEL quedará exento de responsabilidad de los errores de funcionamiento y configuración, así como los daños provocados por las obligaciones anteriormente mencionadas.
        1.3. Uso limitado y no alteración 
A3SATEL concede al CLIENTE una licencia limitada, no exclusiva, intransferible y no sublicenciable del programa/s contratado/s por el CLIENTE de entre los ofrecidos en nuestra web. Esta licencia se otorga únicamente para el uso profesional del CLIENTE y los miembros de su entidad, y no para su distribución, sublicencia o uso por terceros.
El CLIENTE no está autorizado a permitir el uso al software por parte de terceros, con excepción de la provisión de servicios profesionales a sus propios clientes finales (en adelante, los "Clientes Finales"). Cualquier uso del software/s contratados a A3SATEL por el CLIENTE fuera del ámbito de los servicios profesionales prestados a los Clientes Finales, está estrictamente prohibido.
El CLIENTE puede usar el servicio y utilizar la Aplicación y cualesquiera otros contenidos puestos a disposición al efecto por A3SATEL para su descarga desde el Sitio, siempre y cuando el CLIENTE (i) no los transfiera, ceda, transmita, distribuya, venda, sublicencie, alquile, publique, copie, traduzca, convierta, descompile, desensamble, realice ingeniería inversa, transforme o modifique en cualquier otra forma (en todos los casos, ni en todo ni en parte); (ii) ni elimine o altere ninguna advertencia de propiedad, relativa a los derechos de propiedad intelectual o industrial o de exclusión de responsabilidad.
El CLIENTE será el único responsable de todas las posibles consecuencias que se deriven de los incumplimientos de las presentes condiciones por él o por sus autorizados, así como de actos contrarios a la ley, la moral o el orden público. 
        1.4. Actualización 
A3SATEL establecerá las medidas que considere necesarias durante la vigencia del contrato para mantener la permanencia y continuidad de los productos y servicios, atendiendo a una correcta calidad de estos.
A3SATEL se reserva el derecho a realizar cambios y modificaciones estéticas o de funcionalidad, tanto en los productos, como en los medios a través de los que se prestan. A3 SATEL notificará siempre al CLIENTE de manera previa y con antelación suficiente el inicio de cualquier cambio o modificación que pueda repercutir de manera directa al uso de los productos y servicios contratados por el CLIENTE. No pudiendo el CLIENTE reclamar ninguna indemnización por daño o perjuicio asociado a dicha labor de actualización siempre y cuando A3 SATEL hubiese notificado conforme a lo dispuesto en la presente cláusula.
A3SATEL podrá optar por remplazar un producto o servicio que muestre problemas de mal funcionamiento o subsanar las incidencias que se produzcan en los mismos, siempre que no se deba a un mal uso o indebida manipulación del CLIENTE o de sus usuarios autorizados.
        1.5. Limitación geográfica
Los servicios y productos contratados a través de la web https://online.a3satel.com están limitados geográficamente a territorio español. De tal forma que los programas comercializados por A3SATEL no tienen operatividad fuera de territorio español. 
Algunos programas y servicios pueden presentar alguna limitación geográfica adicional. Recomendamos encarecidamente la revisión de la descripción de los servicios y productos que junto a los mismos se prestan en la web con las condiciones especiales antes de adquirirlos. 

    2. PROPIEDAD INTELECTUAL E INDUSTRIAL DEL PRODUCTO 
Todo el contenido del sitio web, la tienda y productos y servicios de A3SATEL, incluidos, entre otros, el logotipo, el diseño, el texto, los softwares, los dibujos técnicos, las configuraciones y los gráficos, se encuentran protegidos por derechos de propiedad intelectual o industrial, de los que A3SATEL es titular y/u ostenta licencia o autorización expresa para su utilización y comunicación pública por parte de los terceros legítimos titulares de las mismas para su inclusión en esta web, tienda o servicios.

Concretamente, el CLIENTE no está autorizado para proceder a la reproducción, reinterpretación total o parcial, uso, explotación, distribución, alteración, difusión, venta, con fines comerciales de los contenidos de esta web y/o sus soluciones sin la previa autorización expresa de A3SATEL. 

Se le recuerda que, concretamente,  el usuario no está autorizado para la reproducción de los elementos que integran o pueden derivarse del producto y/o servicio contratado, su distribución, difusión, comunicación pública y trasformación, explotación (especialmente comercial o industrial) cesión, alquiler, venta, préstamo, realización de correcciones, extracciones y/o reutilizaciones o el ejercicio de cualesquiera otros derechos de propiedad intelectual o industrial sobre ellos que no hayan sido conferidos por GRUPO A3SATEL (propietarios o  que tienen cedidos la totalidad de los derechos de propiedad intelectual, industrial y explotación) expresamente y por escrito. 
Cualquier uso no autorizado previamente será considerado un incumplimiento grave de los derechos de propiedad intelectual o industrial, pudiendo llegar a reclamarse indemnizaciones al respecto.

    3. ERRORES EN EL CONTENIDO
En A3STEL hacemos todos los esfuerzos posibles para suministrar una información veraz, adecuada y actualizada, a pesar de ello, es posible que pueda existir algún error tipográfico, ajeno a la voluntad de A3SATEL. En este caso, lo corregiremos en el momento en el que lo detectemos. De ser el CLIENTE quien detecte el error, por favor comuníquenoslo a administraciononline@a3satel.com.
De existir un error tipográfico en alguno de los precios mostrados y algún CLIENTE hubiera tomado una decisión de compra y/o contratación basada en dicho error, le comunicaremos dicho error y el cliente tendrá derecho a rescindir su compra sin ningún coste por su parte. 
Del mismo modo, es posible que los contenidos de la página web puedan mostrar, en ocasiones, información provisional sobre algunos productos y/o servicios. En el caso que la información facilitada no correspondiera a las características del producto y/o servicio, el CLIENTE tendrá derecho a rescindir su compra sin ningún coste por su parte. 
No obstante, y en cumplimiento de lo dispuesto en el art. 11 y 16 de la LSSI-CE, A3SATEL se pone a disposición de todos los usuarios, autoridades y fuerzas de seguridad, para colaborar de forma activa en la retirada, o en su caso bloqueo, de todos aquellos contenidos que pudieran afectar o contravenir la legislación nacional o internacional, derechos de terceros o la moral y el orden público. 
De este modo, en caso de que el CLIENTE considere que puede haber algún contenido ilegítimo agradeceremos que nos lo notifique de manera inmediata a través del siguiente email: administraciononline@a3satel.com. 

    4. DURACIÓN DEL CONTRATO 
A3SATEL ofrece dos tipos de suscripciones:
        4.1. Suscripción mensual
Salvo indicación expresa en las Condiciones Particulares de la solución adquirida, el contrato de suscripción mensual tendrá una duración inicial de un mes, que se irá prorrogando por iguales periodos salvo que con una antelación mínima de 15 días al término del mes en curso el CLIENTE comunique a A3SATEL tu intención de resolver el contrato. 
        4.2. Suscripción anual
Salvo indicación expresa en las Condiciones Particulares de la solución adquirida, el contrato de suscripción anual tendrá una duración inicial de doce meses desde la fecha de puesta a disposición de ésta. 
Si llegado el término de expiración ninguna de las partes hubiera comunicado a la otra su voluntad de dar por concluido el contrato, con una antelación mínima de 30 días a la fecha de finalización de vigencia de este, se entenderá automáticamente prorrogado como suscripción mensual, comenzando a aplicarse las condiciones establecidas para este tipo de suscripción. 
En este caso, la resolución anticipada por el CLIENTE conllevará aparejada una penalización igual al 50% del precio correspondiente a los servicios por ejecutar. 
        4.3. Disposiciones comunes a ambos tipos de suscripciones
Cualquier notificación que quiera realizarse a A3SATEL sobre la duración del contrato (prórroga, resolución, etc), deberá efectuarse por escrito al email administraciononline@a3satel.com. Aportando la información necesaria para que A3SATEL pueda atender las peticiones realizadas por el CLIENTE. 
Finalizado el contrato y la relación contractual correspondiente, el producto quedará sin uso, perdiendo el cliente el acceso a cualquier información o utilidad que hubiera alojado en el mismo, por lo que deberá adoptar las medidas oportunas para no sufrir perjuicios, siempre dentro de los límites y condiciones del contrato, sin que pueda exigir a GRUPO A3SATEL indemnización de ningún tipo ni traslado de la información o de los datos de un soporte o aplicación a otro. En cualquier caso, se deberá atender a las condiciones particulares de determinados productos o servicios, que determinan o no la devolución de la información al cliente en las condiciones en las mismas recogidas.   

    5. PRECIO Y FORMA DE PAGO 
        5.1. Precio 
Los precios aplicables a cada uno de los productos y servicios están publicados en nuestra web, variando según el tipo de suscripción y producto. Recomendamos presten atención a los precios que se indican en la web junto a cada producto y servicio. 
En cualquier caso, antes de efectuar el cobro, durante el proceso de contratación, se le presentarán todas las tarifas aplicables, incluidos impuestos o cualquier otro tipo de gasto adicional al precio del producto y/o servicio.
En el caso de compras efectuadas fuera del territorio peninsular y/o fuera de territorio español, puede que no se incluyan en el precio todos los impuestos, tasas o aranceles que correspondan, estando éstos a cargo del cliente, quien, a tales efectos, deberá ser conocedor de la política económica y fiscal de su país. En cualquier caso, recordar al CLIENTE, que los servicios y productos contratados a través de la web https://online.a3satel.com están limitados geográficamente a territorio español. De tal forma que los programas comercializados por A3SATEL no tienen operatividad fuera de territorio español. 
        5.2. Forma de pago
Los servicios de A3SATEL pueden ser abonados mediante pago anual o mediante cuotas mensuales. Es el CLIENTE quien selecciona la forma de pago durante el proceso de contratación. Antes de concluir el mismo se detalla la opción seleccionada y la cuota correspondiente. 


        5.3. Método de pago
El pago se realizará mediante tarjeta de crédito a través de la pasarela de pago disponible en https://online.a3satel.com. 
        5.4. Consecuencias ante impagos
Ante el impago de cualquier mensualidad o cuantía debida, el CLIENTE perderá el acceso a los servicios contratados, y a todos los datos almacenados en el programa hasta el momento. No será posible en dicho momento la descarga de copias de seguridad de la información vertida y almacenada en el software en cuestión por el CLIENTE. La información quedará bloqueada pero no suprimida de los servidores durante diez (10) días. Si transcurrido el plazo indicado no se hubiera regularizado por el cliente la situación de impago, se procederá a la supresión definitiva de todos los datos del CLIENTE almacenados en el programa contratado, salvo aquellos que deba conservar por imperativo legal. 
Todo ello sin perjuicio de la reserva de A3SATEL de cuantas acciones le sean propias para reclamar las cuantías debidas y los gastos que las gestiones de reclamo puedan ocasionarles.
En el hipotético supuesto de que el cliente por las especiales circunstancias concurrentes en el momento de la compra se beneficiara de algún tipo de beneficio y/o descuento, y no procediera el pago total del precio establecido, dichas condiciones quedarían revocadas pudiendo A3SATEL reclamar por la totalidad del precio del producto o servicio contratado.
        5.5. Actualización
A3SATEL se reserva el derecho a actualizar el precio vigente para ajustarse a nuevas funcionalidades del producto y para adecuarse a las condiciones del mercado. 
Se comunicará a la mayor brevedad posible al CLIENTE, y, en cualquier caso, con una antelación de 15 días a que los mismos entren en vigor.  El CLIENTE tendrá la opción en tal momento de rescindir el contrato sin coste alguno, salvo los importes que queden pendientes de los servicios ejecutados y del mes en curso. 
        5.6. Promociones
El establecimiento de un descuento, oferta o beneficio por A3SATEL al CLIENTE, en ningún caso, ni aunque sea recurrente en el tiempo, implicará la consolidación de derecho alguno a éste, ni implicará renuncia tácita ni expresa de A3SATEL a cobrar el precio total en sucesivas ocasiones y en supuestos de incumplimiento contractual.  
Para conocer las promociones aplicables, consulta la web: https://online.a3satel.com.


    6. DEVOLUCIÓN
El cliente dispondrá de un plazo de 14 días naturales para el desistimiento de su contratación desde su aceptación, siempre teniendo en cuenta el tipo de producto o servicio contratado. 
Para dichos supuestos el CLIENTE deberá comunicarlo por escrito y de forma fehaciente a A3SATEL enviando un email a administraciononline@a3satel.com. 
La baja del servicio conllevará la pérdida de acceso por el CLIENTE al programa, a cualquier información o utilidad que hubiera alojado en el mismo, sin opción a descargar copia de seguridad de seguridad de los mismos. 

    7. GARANTÍA 
A3SATEL ofrece con carácter general una garantía de 30 días a contar desde la adquisición del programa. No obstante, recomendamos lea las condiciones particulares de cada servicio y producto pues pueden prever garantías adicionales a la indicada. 
La garantía no cubre defectos o fallos resultantes de:
    i. Un uso indebido, negligencia o accidente por parte del CLIENTE o sus autorizados o clientes finales. 
    ii. Uso del software en combinación con hardware, software o sistemas no especificados o aprobados por A3SATEL. 
    iii. Virus, malware o cualquier otro tipo de software malicioso no procedente de A3SATEL. 
    iv. Fallos de funcionamiento debidos a redes de comunicación, interrupciones de servicio de Internet o problemas similares fuera del control de A3SATEL 
    v. Por la falta de medios técnicos necesarios para el correcto funcionamiento de los servicios de A3SATEL responsabilidad del CLIENTE en la forma prevista en la cláusula 1.1. de los presentes términos y condiciones. 

Para ejecutar la garantía el CLIENTE deberá comunicarlo por escrito y de forma fehaciente a A3SATEL enviando un email a administraciononline@a3satel.com. 

    8. ATENCIÓN AL CLIENTE Y ASISTENCIA TÉCNICA
A3SATEL dispone de un email de atención al cliente con el que podrá contactar con nosotros en caso de tener cualquier duda o incidencia relacionado con el producto o servicio adquirido a través de esta tienda online. Para lo cual, podrá remitir un email a administraciononline@a3satel.com, en el que se le contestará en el menor tiempo posible; o ponerse en contacto vía telefónica a los números indicados en la web. 
Del mismo modo, A3SATEL dispone de un Servicio de Soporte al Cliente con el que éste podrá contactar para la resolución de consultas e incidencias relacionadas con los productos y servicios que ponemos a su disposición a través de la tienda online de A3SATEL. 
Ciertas prestaciones del Servicio de Soporte pueden estar sujetas a su previa contratación. Le recomendamos encarecidamente lea detenidamente las condiciones de contratación de cada uno de nuestros productos y servicios disponibles en la web. 

    9. RESPONSABILIDAD DEL CLIENTE
El cliente se obliga a facilitar información cierta y veraz sobre los datos facilitados a través de los formularios de registro, así como de realización de la compra o pedido ya sea información propia, o de terceros, debiendo mantenerla actualizada en todo momento. Atendiendo a esto, EL CLIENTE o USUARIOS AUTORIZADOS aceptan estas condiciones de contratación.
El cliente se compromete a hacer un uso adecuado y lícito de los productos y servicios contratados y de sus contenidos y elementos. En caso de ser aplicable por los productos o servicios contratados, el cliente deberá abstenerse de:
1. Modificar o tratar de modificar los productos o servicios contratados o sus elementos.
2. Suprimir, ocultar o manipular las notas sobre derechos de propiedad intelectual o industrial y demás datos identificativos de los derechos de GRUPO A3SATEL o de terceros, así como los dispositivos técnicos de protección o cualesquiera mecanismos de seguridad o información que puedan insertarse en los productos o servicios contratados.
3. Acceder o usar o intentar acceder o usar de forma fraudulenta, ilícita o no permitida a los productos o servicios contratados, sus contenidos, recursos o áreas restringidas.
4. Introducir o difundir virus informáticos o cualesquiera otros elementos que puedan provocar daños a los productos o servicios contratados, a GRUPO A3SATEL o a terceras partes.
5. Reproducir, distribuir, ceder, prestar, alquilar, realizar comunicación pública o transformar los productos o servicios contratados.
6. Copiar total o parcialmente los productos o servicios contratados ni ceder su uso parcial o total de ninguna forma.
7. Transmitir ninguno de los derechos que tenga sobre los productos o servicios contratados en virtud de este contrato.
8. Divulgar los productos o servicios contratados, publicarlos ni ponerlos de ninguna otra manera a disposición de otras personas.
9. Otorgar licencias, sublicenciar, vender, revender, transferir, asignar, distribuir, explotar comercialmente de cualquier modo, o poner a disposición de terceros los productos o servicios contratados.
10. Modificar o transformar los productos o servicios contratados, ni realizar trabajos derivados de ellas fuera del objeto de este contrato.
11. Compartir los productos o servicios contratados ni ser utilizados por nadie más que por el cliente al margen de lo permitido por este contrato.

El cliente, o los usuarios autorizados que dependen del mismo, son los únicos responsables de cumplir con las condiciones generales y particulares sobre el uso de productos y servicios contratados, así como de los contenidos y opiniones que introduzcan en la utilización de funcionalidades propias, especialmente en las de suscripciones a través de internet, quedando exento GRUPO A3SATEL de cualquier reclamación al respecto. En ningún caso los contenidos introducidos por el cliente podrán ser ilegales o atentar contra las buenas costumbres, la moral o el orden público, o tener fines o efectos ilícitos, delictivos, prohibidos o lesivos de derechos o intereses de terceros, ni constituir o inducir a realizar actividades delictivas, discriminatorias, denigrantes, violentas o pornográficas.
El cliente deberá atender a la información de los productos y servicios dispuesta y obtenida de la tienda GRUPO A3SATEL, quedando exento de responsabilidad GRUPO A3SATEL de las consecuencias que se deriven de la interpretación, decisiones, cálculos o cualquieras otras que adopte el cliente atendiendo a dicha información. 
El cliente es un profesional experto en su sector y los productos de GRUPO A3SATEL son meras herramientas destinadas a complementar, pero no a sustituir, la labor del profesional. GRUPO A3SATEL pone todos los medios materiales, humanos y técnicos a su alcance para obtener procesar y suministrar la información actual, veraz y completa. No obstante, debido a la gran cantidad de material con el que trabaja GRUPO A3SATEL, así como sus proveedores de información y debido al hecho de que la información se elabora a partir de datos suministrados por fuentes sobre las que GRUPO A3SATEL no tiene control y cuya verificación no siempre es posible, existiendo siempre la posibilidad de que se produzca un error, y teniendo en cuenta también que parte de dicha información puede estar elaborada con base en criterios opinables, GRUPO A3SATEL no será responsable de las consecuencias que se pudiesen derivar para el cliente o para sus clientes o terceros de la utilización de la información suministrada, incluso en el caso que dicha información fuese incorrecta o incompleta, circunstancia ésta que es comprendida y aceptada de forma expresa por el cliente.
Asimismo, es obligación del cliente visitar periódicamente la página web de GRUPO A3SATEL para comprobar la publicación por GRUPO A3SATEL de nuevas versiones de sus productos o servicios o correcciones destinadas a subsanar errores detectados y corregidos.

    10. OBLIGACIONES Y EXENCION DE RESPONSABILIDADES DE A3SATEL
Los productos y servicios de GRUPO A3SATEL son el resultado de un trabajo constante de análisis e investigación por parte de personal técnico y profesional altamente cualificado.
No obstante, y pese a aplicar un sistema de calidad a nuestros productos y servicios, es posible que debido a circunstancias aleatorias y ajenas a la voluntad de GRUPO A3SATEL, se produzcan alteraciones, deficiencias en el uso del producto o servicio. En este sentido, GRUPO A3SATEL no se considerará responsable cuando se den las siguientes circunstancias ajenas y cuyos factores quedan fuera de su control:
1. Cuando el usuario mantenga deficiencias técnicas o de otro tipo en sus dispositivos, sistemas, aplicaciones de conexión, o en su navegador.
2. Cuando existan incompatibilidades o deficiencias en los equipos y dispositivos del usuario y los programas o sistemas de los productos y servicios que proporciona GRUPO A3SATEL.
3. Cuando se produzcan interrupciones, ataques de virus informáticos, desconexiones o averías debido a deficiencias de proveedores del servicio de internet o por intromisiones o actuaciones ilícitas de un tercero.
4. Cuando se produzca una desconexión o interrupción del producto o servicio debido al cumplimiento de resoluciones administrativas o judiciales.
5. Cuando se den circunstancias o casos fortuitos, o situaciones de fuerza mayor que queden fuera del control de GRUPO A3SATEL
En cualquier caso, GRUPO A3SATEL no será responsable cuando se produzcan pérdidas al cliente, que no fueran atribuibles a incumplimiento alguno por parte de GRUPO A3SATEL, incluyendo las pérdidas indirectas como el lucro cesante, de ingresos o de cualquier otro tipo empresarial. Tampoco será responsable GRUPO A3SATEL cuando las razones alegadas por el cliente no fueran razonablemente previsibles con ocasión del inicio de la relación.
GRUPO A3SATEL realizará sus mejores esfuerzos para asegurar la disponibilidad de los contenidos de productos y servicios, así como la ausencia de errores en cualquier transmisión de información que pudiera tener lugar. No obstante, debido a la naturaleza misma y accesibilidad de determinados productos y servicios a través de internet, no es posible garantizar tales extremos.
Asimismo, el acceso a determinados productos y servicios de GRUPO A3SATEL puede ocasionalmente verse suspendido o por la necesidad de realizar trabajos de reparación, mantenimiento, de introducción, o actualización de contenidos, así como de otros productos y servicios o nuevas funcionalidades de estos. GRUPO A3SATEL procurará limitar la frecuencia, así como la duración de dichas tareas, para en la medida de lo posible causar el menor perjuicio al cliente, intentando en todo momento mantenerles informados de los mismos, sin que por ello se derive ningún tipo de responsabilidad para GRUPO A3SATEL.
En el supuesto de que el uso de un producto o servicio (o partes del mismo) fuera causa de pleito o acción legal por presunta violación de los derechos de terceras partes y dicho uso pudiese producir tal violación, GRUPO A3SATEL podrá realizar las modificaciones o cambios necesarios para que el cliente pueda seguir con dicho producto o servicio (o partes del mismo), sustituirlo por otro de contenidos y/o funcionalidad y eficacia equivalentes que no infrinjan los derechos de esas terceras partes, también podrá valorar abonar una parte razonable de los derechos pagados con el fin de compensar al cliente la terminación del uso del producto o servicio (o parte del mismo).
En ocasiones los textos o contenidos incluidos en productos o servicios de GRUPO A3SATEL son obtenidos de Diarios y Boletines Oficiales, por lo que GRUPO A3SATEL declina cualquier tipo de responsabilidad por errores materiales, aritméticos o de otro tipo que puedan padecer los mismos. Además, GRUPO A3SATEL y los autores de contenidos no aceptan responsabilidad por las posibles consecuencias ocasionadas a las personas físicas o jurídicas que actúen o dejen de actuar como resultado de alguna información vertida en dichos contenidos.
En cualquier caso, y sin perjuicio de otras exclusiones y limitaciones contenidas en el presente contrato, la responsabilidad de GRUPO A3SATEL por los daños imputables directamente al producto o servicio se limitará al precio de la adquisición inicial del mismo en el momento de la reclamación, salvo que se establezca la existencia de dolo o negligencia grave por parte de GRUPO A3SATEL.

    11. CESIÓN DEL CONTRATO
Queda expresamente prohibido que las partes puedan ceder su posición asumida en virtud del presente contrato, salvo autorización por escrito de la respectiva contraparte.

    12. CAUSAS DE RESOLUCIÓN DE CONTRATO
El presente contrato se podrá resolver a instancia de cualquiera de las partes en los supuestos establecidos al efecto, de mutuo acuerdo o por terminación del plazo de duración previsto, o de cualquiera de sus prorrogas, siempre que haya mediado preaviso fehaciente en estos dos últimos casos en los plazos y formas indicados en el presente contrato.
A3SATEL podrá resolver el contrato si:
        ◦ Se diese la falta de pago total o parcial por parte del cliente del precio establecido, conservando su derecho a reclamar a través de cualquier vía las cantidades pendientes de pago.
        ◦ Por un uso del producto o servicio por parte del cliente o de los usuarios autorizados que contravenga lo dispuesto en estas condiciones generales o en otras particulares que traiga asociadas el producto o servicio.
        ◦ Cuando el cliente o los usuarios autorizados incurran en actividades ilícitas, contrarias a la buena fe, a la moral o al orden público o que perjudiquen la imagen, los derechos o los intereses de A3 SATEL o de terceros.
        ◦ Si el cliente vulnera la prohibición de colgar o subir contenidos ilícitos o ilegales.
        ◦ Si el cliente vulnera la prohibición de no usar herramientas de búsqueda, volcado, extracción masiva, cesión, exportación, copia, descarga, guardado o impresión de partes sustanciales de los productos o servicios contratados fuera de los límites y condiciones del presente contrato.
Igualmente se podrá resolver el contrato cuando a instancia de la parte que no haya incurrido en causas de resolución, así como cuando exista una declaración judicial en concurso de acreedores (en cuyo caso el cliente se compromete a solicitar del Administrador Concursal y, en su caso, del juzgado que se inste la resolución del contrato). También se podrá resolver el contrato cuando concurra cualquier otra causa de resolución contractual establecida en el ordenamiento jurídico, o se produzca la extinción de la personalidad jurídica de una de las partes.

    13. NOTIFICACIONES Y COMUNICACIONES
Las notificaciones o comunicaciones que A3SATEL deba realizar al cliente respecto a la ejecución o cumplimiento del presente contrato se efectuarán mediante correo electrónico a la dirección que éste haya facilitado en el registro como usuario. En determinadas ocasiones, podrá efectuarse la notificación al domicilio que el cliente haya facilitado en el pedido o en cualquiera otro que haya indicado al efecto. 
Las comunicaciones que el CLIENTE deba realizar a A3SATEL deberán realizarse al email administraciononline@a3satel.com de forma preferente, o a la dirección postal C/Camino de los Descubrimientos 11, 1º planta, 41092, Sevilla.
    14. POLITICA DE PRIVACIDAD Y COOKIES
A3SATEL en el cumplimiento de la normativa española de protección de datos de carácter personal, garantiza el cumplimiento íntegro de las obligaciones en ellas descritas, así como la implementación de las medidas de seguridad contempladas en la Ley Orgánica 3/2018, de Protección de Datos Personales y garantía de los derechos iniciales, en adelante LOPDGDD, y el Reglamento Europeo (UE) 2016/679, en adelante RGPD. 
Para más información sobre el tratamiento de sus datos es importante que lea y conozca la “Política de Protección de datos y Privacidad” y la “Política de Cookies” de esta web. Por ello el equipo de A3SATEL recomienda de nuevo su lectura y comprensión y la abstención del uso de la web o cualquiera de su contenido/servicios en caso de no estar de acuerdo con las mismas. 
Es necesario que sepa que en A3SATEL recabamos datos de carácter personal mediante los formularios de contacto, y por medio de la plataforma integrada en esta web y aquellas a las que de la misma titularidad redirija.
El uso de sus datos personales será el de ofrecimiento comercial de nuestros productos, servicios, e información sobre promociones. 
Aunque es necesario que acepte la “Política de Privacidad y Protección de datos” en la que se le informa de manera más detallada del uso y tratamiento de sus datos, todo usuario tiene la posibilidad de ejercer sus derechos de acceso, rectificación, limitación, portabilidad, cancelación u oposición, a través del siguiente correo: administraciononline@a3satel.com
En la “Política de Cookies” le informamos que la web de A3SATEL cuenta con Cookies en favor de dar más valido sus servicios. Rogamos consulte el apartado de Privacidad y Cookies.

    15. LEGISLACIÓN Y JURISDICCIÓN APLICABLE
GRUPO A3SATEL tiene su sede en España, por lo que queda obligada a las leyes españolas y europeas en materia de contratación. 
El CLIENTE acepta que las reclamaciones o quejas contra A3SATEL que provengan o estén relacionadas con la contratación electrónica de sus servicios serán resueltas por el tribunal de la jurisdicción competente situado en Sevilla.
Si el usuario accede a este sitio desde una ubicación fuera de España, es responsable de cumplir con todas las leyes locales e internacionales que te resulten de aplicación. 

    16. MODIFICACIÓN
GRUPO A3SATEL podrá modificar este aviso de legal en cualquier momento publicándose en esta web, en el que constará siempre la fecha de la última actualización aplicable. Las modificaciones introducidas no tendrán carácter retroactivo, sino que empezarán a ser de aplicación en el momento de su publicación.
Es su obligación la lectura completa del presente texto legal y de los demás publicados en nuestra web antes de navegar por la misma y/o realizar una compra en su comercio online.

ÚLTIMA MODIFICACIÓN 20 DE JUNIO DE 2024`}

}
