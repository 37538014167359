































import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component({
	name: 'error',
})
export default class Error extends Vue {
	@Prop({ required: false }) error: any;

	head() {
		return {
			bodyAttrs: {
				class: 'page-error',
			},
		};
	}
}
