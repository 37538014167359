































import { Component, Vue } from 'nuxt-property-decorator';
import { Prop } from 'vue-property-decorator';
import FooterModel from '~/models/footer_model';
import axios from 'axios';

type Data = {
	title: string;
	link: string;
};

@Component({})
export default class Sitemap extends Vue {
	@Prop({ required: true }) footer: any;

	public posts: Object | null = {};
	public post: Object | null = {};

	async fetch() {
		const url = process.env.NUXT_ENV_BLOG_URL + '/wp-json/wp/v2/posts?per_page=4';
		const { data: posts } = await axios.get<Data[]>(url);
		this.posts = posts;
	}
}
