























































	import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import FormatHelper from '~/helpers/format-helper';
import OrderService from '~/services/OrderService';
import ApiHelper from '~/helpers/api-helper';
import Order from '~/models/order';

@Component({
	components: {},
})
export default class ShoppingCart extends Vue {
	mounted() {
		this.setLocalStorageCart();
	}

	get currentProducts() {
		return this.$store.state.shopping_cart;
	}

	get userManaged() {
		return this.$store.state.user_managed;
	}

	@Watch('userManaged')
	onUserManaged() {
		this.setLocalStorageCart();
	}

	get total() {
		let total = 0;
		if (this.currentProducts.lines.length) {
			for (const product of this.currentProducts.lines) {
				total += product.amount * product.quantity;
			}
		}
		return total;
	}

	formatNumber(value: any) {
		return FormatHelper.formatPrice(value);
	}

	setLocalStorageCart() {
		if (process.client) {
			const order_id = localStorage.getItem('ec_order_id');
			if (order_id) {
				this.getOrder(order_id);
			} else {
				this.$store.commit('setCartGtmLoaded', true);
			}
		}
	}

	removeProduct(product: any) {
		const shoppingCart = JSON.parse(JSON.stringify(this.$store.state.shopping_cart.lines));
		const index = shoppingCart.findIndex((item: { id: any }) => item.id === product.id);
		shoppingCart.splice(index, 1);

		const indexChild = shoppingCart.findIndex((item: {parent_ec_product_configuration_id: any }) =>
				item.parent_ec_product_configuration_id === product.ec_product_configuration_id);
		if (indexChild != -1) {
			shoppingCart.splice(indexChild, 1);
		}

		const order = new Order();
		order.id = this.$store.state.shopping_cart.id;
		order.lines = shoppingCart;
		this.$nuxt.$loading.start();
		OrderService.update(this.$axios, order)
			.then((res: any) => {
				if (res.status === 200) {
					this.$nuxt.$loading.finish();
					this.$store.commit('setAlert', {
						show: true,
						message: 'Artículo eliminado correctamente',
						type: 'success',
					});
					this.$store.commit('updateShoppingCart', res.data);
				}
			})
			.catch((error: any) => {
				this.$nuxt.$loading.finish();
				ApiHelper.handleErrors(error, {}, this.$store);
			});
	}

	getOrder(order_id: any) {
		OrderService.get(this.$axios, this.$store, order_id);
	}
}
