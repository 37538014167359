import OrderLine from '~/models/order_line';

export default class Order {
	id: any;
	date: any;
	total_vat: any;
	total_amount: any;
	status: any;
	lines: OrderLine[];

	constructor() {
		this.id = null;
		this.date = '';
		this.total_vat = '';
		this.total_amount = '';
		this.status = '';
		this.lines = [];
	}

	public static copy(model: any) {
		const aux = new Order();
		aux.id = model.id;
		aux.date = model.date;
		aux.total_vat = model.total_vat;
		aux.total_amount = model.total_amount;
		aux.status = model.status;
		aux.lines = model.lines;
		return aux;
	}
}
