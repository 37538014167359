import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  AtomsIcon: () => import('../../components/atoms/Icon.vue' /* webpackChunkName: "components/atoms-icon" */).then(c => wrapFunctional(c.default || c)),
  AtomsLogo: () => import('../../components/atoms/Logo.vue' /* webpackChunkName: "components/atoms-logo" */).then(c => wrapFunctional(c.default || c)),
  CoreAlert: () => import('../../components/core/Alert.vue' /* webpackChunkName: "components/core-alert" */).then(c => wrapFunctional(c.default || c)),
  CoreBreadcrumb: () => import('../../components/core/Breadcrumb.vue' /* webpackChunkName: "components/core-breadcrumb" */).then(c => wrapFunctional(c.default || c)),
  CoreCheckoutLegalText: () => import('../../components/core/CheckoutLegalText.vue' /* webpackChunkName: "components/core-checkout-legal-text" */).then(c => wrapFunctional(c.default || c)),
  CoreCookiesPolicy: () => import('../../components/core/CookiesPolicy.vue' /* webpackChunkName: "components/core-cookies-policy" */).then(c => wrapFunctional(c.default || c)),
  CoreFeature: () => import('../../components/core/Feature.vue' /* webpackChunkName: "components/core-feature" */).then(c => wrapFunctional(c.default || c)),
  CoreFooter: () => import('../../components/core/Footer.vue' /* webpackChunkName: "components/core-footer" */).then(c => wrapFunctional(c.default || c)),
  CoreHeader: () => import('../../components/core/Header.vue' /* webpackChunkName: "components/core-header" */).then(c => wrapFunctional(c.default || c)),
  CoreLegalNotice: () => import('../../components/core/LegalNotice.vue' /* webpackChunkName: "components/core-legal-notice" */).then(c => wrapFunctional(c.default || c)),
  CoreLegalTerm: () => import('../../components/core/LegalTerm.vue' /* webpackChunkName: "components/core-legal-term" */).then(c => wrapFunctional(c.default || c)),
  CoreLoader: () => import('../../components/core/Loader.vue' /* webpackChunkName: "components/core-loader" */).then(c => wrapFunctional(c.default || c)),
  CoreMainNav: () => import('../../components/core/MainNav.vue' /* webpackChunkName: "components/core-main-nav" */).then(c => wrapFunctional(c.default || c)),
  CoreNewsletter: () => import('../../components/core/Newsletter.vue' /* webpackChunkName: "components/core-newsletter" */).then(c => wrapFunctional(c.default || c)),
  CoreNotice: () => import('../../components/core/Notice.vue' /* webpackChunkName: "components/core-notice" */).then(c => wrapFunctional(c.default || c)),
  CorePageContainer: () => import('../../components/core/PageContainer.vue' /* webpackChunkName: "components/core-page-container" */).then(c => wrapFunctional(c.default || c)),
  CorePrivacyPolicy: () => import('../../components/core/PrivacyPolicy.vue' /* webpackChunkName: "components/core-privacy-policy" */).then(c => wrapFunctional(c.default || c)),
  CorePrivacySummaryText: () => import('../../components/core/PrivacySummaryText.vue' /* webpackChunkName: "components/core-privacy-summary-text" */).then(c => wrapFunctional(c.default || c)),
  CoreProduct: () => import('../../components/core/Product.vue' /* webpackChunkName: "components/core-product" */).then(c => wrapFunctional(c.default || c)),
  CoreShoppingCart: () => import('../../components/core/ShoppingCart.vue' /* webpackChunkName: "components/core-shopping-cart" */).then(c => wrapFunctional(c.default || c)),
  CoreShoppingStepper: () => import('../../components/core/ShoppingStepper.vue' /* webpackChunkName: "components/core-shopping-stepper" */).then(c => wrapFunctional(c.default || c)),
  CoreSitemap: () => import('../../components/core/Sitemap.vue' /* webpackChunkName: "components/core-sitemap" */).then(c => wrapFunctional(c.default || c)),
  CoreSlider: () => import('../../components/core/Slider.vue' /* webpackChunkName: "components/core-slider" */).then(c => wrapFunctional(c.default || c)),
  CoreTermsConditions: () => import('../../components/core/TermsConditions.vue' /* webpackChunkName: "components/core-terms-conditions" */).then(c => wrapFunctional(c.default || c)),
  FunnelCart: () => import('../../components/funnel/Cart.vue' /* webpackChunkName: "components/funnel-cart" */).then(c => wrapFunctional(c.default || c)),
  FunnelCheckoutForm: () => import('../../components/funnel/CheckoutForm.vue' /* webpackChunkName: "components/funnel-checkout-form" */).then(c => wrapFunctional(c.default || c)),
  FunnelShoppingStepCart: () => import('../../components/funnel/ShoppingStepCart.vue' /* webpackChunkName: "components/funnel-shopping-step-cart" */).then(c => wrapFunctional(c.default || c)),
  FunnelShoppingStepCheckout: () => import('../../components/funnel/ShoppingStepCheckout.vue' /* webpackChunkName: "components/funnel-shopping-step-checkout" */).then(c => wrapFunctional(c.default || c)),
  FunnelShoppingStepRegistration: () => import('../../components/funnel/ShoppingStepRegistration.vue' /* webpackChunkName: "components/funnel-shopping-step-registration" */).then(c => wrapFunctional(c.default || c)),
  FunnelSignupForm: () => import('../../components/funnel/SignupForm.vue' /* webpackChunkName: "components/funnel-signup-form" */).then(c => wrapFunctional(c.default || c)),
  FunnelStripeDemo: () => import('../../components/funnel/StripeDemo.vue' /* webpackChunkName: "components/funnel-stripe-demo" */).then(c => wrapFunctional(c.default || c)),
  IconsCheck: () => import('../../components/icons/Check.vue' /* webpackChunkName: "components/icons-check" */).then(c => wrapFunctional(c.default || c)),
  IconsEmptyCheckbox: () => import('../../components/icons/EmptyCheckbox.vue' /* webpackChunkName: "components/icons-empty-checkbox" */).then(c => wrapFunctional(c.default || c)),
  InputsCheckbox: () => import('../../components/inputs/Checkbox.vue' /* webpackChunkName: "components/inputs-checkbox" */).then(c => wrapFunctional(c.default || c)),
  InputsInputSelect: () => import('../../components/inputs/InputSelect.vue' /* webpackChunkName: "components/inputs-input-select" */).then(c => wrapFunctional(c.default || c)),
  InputsInputText: () => import('../../components/inputs/InputText.vue' /* webpackChunkName: "components/inputs-input-text" */).then(c => wrapFunctional(c.default || c)),
  InputsPageSelect: () => import('../../components/inputs/PageSelect.vue' /* webpackChunkName: "components/inputs-page-select" */).then(c => wrapFunctional(c.default || c)),
  InputsProductChildren: () => import('../../components/inputs/ProductChildren.vue' /* webpackChunkName: "components/inputs-product-children" */).then(c => wrapFunctional(c.default || c)),
  InputsProvinceSelect: () => import('../../components/inputs/ProvinceSelect.vue' /* webpackChunkName: "components/inputs-province-select" */).then(c => wrapFunctional(c.default || c)),
  InputsRadioButton: () => import('../../components/inputs/RadioButton.vue' /* webpackChunkName: "components/inputs-radio-button" */).then(c => wrapFunctional(c.default || c)),
  ModalsModalCheckoutConsent: () => import('../../components/modals/ModalCheckoutConsent.vue' /* webpackChunkName: "components/modals-modal-checkout-consent" */).then(c => wrapFunctional(c.default || c)),
  ModalsModalChildService: () => import('../../components/modals/ModalChildService.vue' /* webpackChunkName: "components/modals-modal-child-service" */).then(c => wrapFunctional(c.default || c)),
  ModalsModalDemo: () => import('../../components/modals/ModalDemo.vue' /* webpackChunkName: "components/modals-modal-demo" */).then(c => wrapFunctional(c.default || c)),
  ModalsModalNewLegalTerms: () => import('../../components/modals/ModalNewLegalTerms.vue' /* webpackChunkName: "components/modals-modal-new-legal-terms" */).then(c => wrapFunctional(c.default || c)),
  ModalsModalSignUp: () => import('../../components/modals/ModalSignUp.vue' /* webpackChunkName: "components/modals-modal-sign-up" */).then(c => wrapFunctional(c.default || c)),
  CorePageSectionBanner: () => import('../../components/core/page_sections/PageSectionBanner.vue' /* webpackChunkName: "components/core-page-section-banner" */).then(c => wrapFunctional(c.default || c)),
  CorePageSectionFaqs: () => import('../../components/core/page_sections/PageSectionFaqs.vue' /* webpackChunkName: "components/core-page-section-faqs" */).then(c => wrapFunctional(c.default || c)),
  CorePageSectionFeaturesAndServices: () => import('../../components/core/page_sections/PageSectionFeaturesAndServices.vue' /* webpackChunkName: "components/core-page-section-features-and-services" */).then(c => wrapFunctional(c.default || c)),
  CorePageSectionHeader: () => import('../../components/core/page_sections/PageSectionHeader.vue' /* webpackChunkName: "components/core-page-section-header" */).then(c => wrapFunctional(c.default || c)),
  CorePageSectionHighLightPoints: () => import('../../components/core/page_sections/PageSectionHighLightPoints.vue' /* webpackChunkName: "components/core-page-section-high-light-points" */).then(c => wrapFunctional(c.default || c)),
  CorePageSectionProductConfiguration: () => import('../../components/core/page_sections/PageSectionProductConfiguration.vue' /* webpackChunkName: "components/core-page-section-product-configuration" */).then(c => wrapFunctional(c.default || c)),
  CorePageSectionProductDataSheet: () => import('../../components/core/page_sections/PageSectionProductDataSheet.vue' /* webpackChunkName: "components/core-page-section-product-data-sheet" */).then(c => wrapFunctional(c.default || c)),
  CorePageSectionProductGrid: () => import('../../components/core/page_sections/PageSectionProductGrid.vue' /* webpackChunkName: "components/core-page-section-product-grid" */).then(c => wrapFunctional(c.default || c)),
  CorePageSectionSolutions: () => import('../../components/core/page_sections/PageSectionSolutions.vue' /* webpackChunkName: "components/core-page-section-solutions" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
